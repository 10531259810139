<template>
    <section id="seller-faq" class="vendor-questions  text-right pb-5 pt-0">
        <div v-if="faqs != []" class="container">
            <h5 class="text-color-444 weight-bold mt-5"> سوالات    <span class="text-color-themeBlue weight-bold">فروشندگان</span>  </h5>
            <p class="fontsize12 text-color-999">sellers questions</p>

            <div class="accordion " id="accordion1">
                <faqItem v-for="(faq, index) in faqs" :key="index" :faq="faq" :index="index" :categoryId="1" />
            </div>

            <!-- <button type="button" class="bg-none p-3 border-radius10 text-color-666 fontsize12 box-shaddow20">
                سوالات بیشتر
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-chevron-double-down" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" stroke="#ccc"/>
                    <path fill-rule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" stroke="#ccc"/>
                  </svg>
            </button> -->
        </div>
    </section>
</template>
<script> 
    import faqItem from '../faq/faqItem.vue'

    export default {
        components: {
            faqItem
        },
        props:[
            'faqs'
        ],
        data() {
            return {
            }
        },
        created() {
        },
        methods: {
            
        }
    }
</script>