<template>
    <footer class=" bg-color-themeBlue p-3">
        <section id="seller-scroll-t-top" class="scroll-to-top  rounded bg-color-themeBlue">
            <button class="p-2" type="button" @click="scrollToTop0();">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.7256 4.25L11.7256 19.25" stroke="#fff" stroke-width="2.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M5.70124 10.2998L11.7252 4.2498L17.7502 10.2998" stroke="#fff" stroke-width="2.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>

        </section>
        <div class="container d-flex justify-content-between align-items-baseline">
            <span class="fontsize11 text-white text-right">
                تمامی حقوق برای برند آلبا  مدیا محفوظ می باشد .  طراحی شده توسط گروه برنامه نویسی <a href="http://shetabit.com/" target="_blank"
                class="fs-16 text-white weight-bold">شتاب</a>
            </span>
            <div>
                <img src="../../../../assets/images/linus-logo-white.png" alt="">
            </div>
        </div>
    </footer>
</template>
<script>
    export default {
        mounted() {
            let scrollToTop= document.getElementById('seller-scroll-t-top');
            window.onscroll = function () {
              if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
                    scrollToTop.style.bottom = "30px";
              } else if (document.body.scrollTop <= 300 || document.documentElement.scrollTop <= 300) {
                scrollToTop.style.bottom = "-43px";
                }
            };
        },
        methods: {
            scrollToTop0() {
                document.documentElement.scrollTo({
                    top: 0,
                    behavior: "smooth"
                })
            }
        }
    }
</script>